.about {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  z-index: 10;
  will-change: opacity;
}

.about_content_contain {
  min-height: 100%;
  display: grid;
  grid-template-columns: 40% 60%;
  box-shadow: 0px 3px 8px -5px rgba(0, 0, 0, 0.8);
}

.info_section {
  margin-bottom: 25px;
  max-width: 500px;
}

.info_section:last-child {
  margin-bottom: 0;
}

.line_wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.line {
  height: 10px;
  width: 10px;
  background: var(--theme);
  margin-right: 10px;
  border-radius: 1px;
}

/* ---- PIC ---- */

.about_pic {
  position: relative;
  overflow: hidden;
  background: rgb(252, 252, 252);
}

.about_pic_img {
  height: 100%;
  width: 100%;
  background: var(--theme);
  background-repeat: no-repeat;
  background-position: 65% 30%;
  background-size: cover;
  position: relative;
  z-index: 20;
}

/* ---- ABOUT ---- */
.about_info {
  background: rgb(253, 253, 253);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  padding: 50px;
  position: relative;
  z-index: 10;
  transform: translate3d(-30px, 0, 0);
  animation: info-enter 400ms cubic-bezier(0.3, 0.6, 0.5, 1) forwards;
}

@keyframes info-enter {
  to {
    transform: translate3d(0, 0, 0);
  }
}

/* ---- BIO ---- */

.about_bio_title {
  font-size: 46px;
  font-family: Helvetica;
  color: var(--theme);
}

.about_bio_info {
  margin-top: 16px;
}

.about_bio_info > p:first-child {
  margin-bottom: 16px;
}

/* ---- SKILLS ---- */

.about_skills_title {
  color: rgb(40, 40, 40);
  font-size: 0.7rem;
  letter-spacing: 1.5px;
  font-weight: normal;
}

.about_skills_title::before {
  content: "00 ";
  color: var(--theme);
}

.about_skills_list {
  list-style: none;
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: auto auto auto;
  grid-gap: 2px;
  max-width: 400px;
}

.about_skills_list > li {
  font-family: Helvetica;
  text-decoration: none;
  margin-right: 10px;
  font-size: 10px;
  height: 25px;
  letter-spacing: 0.4px;
  display: flex;
  align-items: center;
  color: rgb(80, 80, 80);
  border-bottom: 1px solid rgba(30, 130, 240, 0.8);
}

.about_skills_list > li:nth-child(n + 13) {
  border: none;
}

.skillset_icon {
  height: 100%;
  width: 10px;
  opacity: 0.5;
  margin-right: 6px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

/* ---- CONTACT ---- */

.about_link_title {
  color: rgb(40, 40, 40);
  font-size: 0.7rem;
  letter-spacing: 1.5px;
  font-weight: normal;
}

.about_link_title::before {
  content: "02 ";
  color: var(--theme);
}

.about_contact_title {
  color: rgb(40, 40, 40);
  font-size: 0.7rem;
  letter-spacing: 1.5px;
  font-weight: normal;
}

.about_contact_title::before {
  content: "01 ";
  color: var(--theme);
}

.about_contact_info {
  margin-bottom: 25px;
}

.about_contact_info a {
  text-decoration: none;
  color: var(--theme);
  letter-spacing: 0.3px;
}

.about_contact_list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.about_contact_item {
  display: flex;
  align-items: center;
  height: 15px;
  margin-right: 10px;
}

.contact_icon {
  margin-right: 6px;
  opacity: 0.7;
  height: 100%;
  width: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.about_contact_item > a {
  color: rgb(40, 40, 40);
  text-decoration: none;
  letter-spacing: 1px;
  font-size: 10px;
}

/* MEDIA */
@media only screen and (max-width: 568px) {
  .about_contact_list {
    flex-direction: column;
  }
}

@media only screen and (max-width: 868px) {
  .about_content_contain {
    grid-template-columns: auto;
    grid-template-rows: 50vh;
  }

  .about_pic_img {
    transform: translate3d(0, -40px, 0);
    animation-delay: 30ms;
  }

  .about_info {
    font-size: 16px;
    padding: 35px;
    transform: translate3d(0, -30px, 0);
    animation-duration: 400ms;
  }

  .info_section {
    margin-bottom: 25px;
  }

  .about_contact_title,
  .about_skills_title {
    letter-spacing: 1.8px;
  }

  .about_contact_item {
    height: 20px;
  }

  .contact_icon {
    width: 10px;
  }

  .about_contact_item > a {
    font-family: Helvetica;
    font-size: 10px;
  }
}
