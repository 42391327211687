.projects {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  will-change: opacity;
}

.projects_content {
  margin: 0 5%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 15em);
  grid-gap: 60px;
  padding: 60px;
  justify-content: center;
  align-items: start;
}

/* Project item */

.projects_item {
  border-radius: 8px;
  overflow: hidden;
  opacity: 0;
  transform: translate3d(0, 30px, 0);
  opacity: 0;
  box-shadow: 0px 10px 40px -20px rgba(0, 0, 0, 0.6);
  animation: proj-enter 450ms cubic-bezier(0.3, 0.6, 0.5, 1) forwards;
}

@keyframes proj-enter {
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.project_item_content {
  height: 100%;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
}

.content_image {
  height: 11.5em;
  padding: 30px;
  background: #e4e4e4;
  overflow: hidden;
}

.content_image_letter {
  height: 100%;
  width: 100%;
  opacity: 0.9;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.content_info {
  padding: 22px 22px 0 22px;
  background: #fafafa;
}

.content_title {
  color: rgb(60, 60, 60);
  font-size: 11px;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
  font-weight: 100;
}

.content_tech {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
}

.content_tech_item {
  margin: 5px 5px 0 0;
  background: #6fc2d1;
  padding: 0px 2px 0px 14px;
  font-size: 10px;
  letter-spacing: 0.8px;
  color: rgb(50, 50, 50);
  position: relative;
  line-height: 1;
  z-index: 3;
}

.content_tech_item::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #fafafa;
  z-index: -1;
}

.content_tech_item::before {
  content: "";
  position: absolute;
  color: transparent;
  height: 10px;
  width: 10px;
  bottom: 0;
  left: 0;
  background: inherit;
  z-index: 1;
}

.content_desc {
  color: rgb(50, 50, 50);
  font-size: 14px;
  padding: 5px 0 10px 0;
  line-height: 1.4;
  border-bottom: 1px solid rgb(0, 120, 255);
}

.content_links {
  padding: 10px 0;
}

.contnet_link_item {
  text-decoration: none;
  color: rgb(50, 50, 50);
  font-size: 18px;
  margin-right: 6px;
  padding: 0;
  line-height: 0.9;
}

.contnet_link_item > svg {
  vertical-align: middle;
}

/* MEDIA */

@media only screen and (max-width: 1200px) {
  .projects_content {
    margin: 0;
  }
}

@media only screen and (max-width: 868px) {
  .projects_content {
    grid-template-columns: repeat(auto-fit, minmax(auto, 18em));
    grid-gap: 30px;
    padding: 60px 30px 30px 30px;
  }

  .content_image {
    height: 16em;
  }
}
