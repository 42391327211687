.nav {
  order: 1;
  position: relative;
  box-shadow: 0px 1px 2px -2px rgba(0, 0, 0, 0.8);
}

.nav_links {
  height: 100%;
  width: 100%;
  background: white;
  padding: 10px;
  border-radius: 0 0 2px 2px;
  position: relative;
  z-index: 500;
}

.nav_item {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
  color: rgb(40, 40, 40);
  text-decoration: none;
  margin-right: 10px;
  letter-spacing: 0.6px;
  position: relative;
  padding: 4px 6px;
  z-index: 5;
}

.nav_item:hover .item_hov {
  transform: scale3d(1, 1, 1);
  transform-origin: left;
  opacity: 1;
}

.item_hov {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgb(215, 215, 215);
  left: 0;
  bottom: 0;
  z-index: -1;
  transform-origin: right;
  opacity: 0;
  transform: scale3d(0.6, 1, 1);
  transition: transform 250ms ease-out, opacity 250ms ease-out;
}

.nav_item:last-child {
  margin-right: 0;
}

/* Pseudo  */

.nav_item:nth-child(1)::before {
  content: "+";
}

.nav_item:nth-child(2)::before {
  content: "./CD/";
}

.active {
  color: rgb(0, 70, 255);
}

.nav_item.active::before,
.nav_item.active::after {
  display: initial;
}

@media only screen and (max-width: 868px) {
  .nav {
    z-index: 50;
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: z-index 300ms step-start, opacity 200ms ease-out,
      transform 200ms ease-out;
  }

  .nav-hidden {
    opacity: 0;
    transform: translate3d(0, 15px, 0);
    z-index: -1;
    transition: z-index 300ms step-end, opacity 200ms ease-out,
      transform 200ms ease-out;
  }

  .nav-menu {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
    outline: none;
    padding: 30px 20px;
    cursor: pointer;
  }

  .nav-menu-mobile {
    height: 1px;
    width: 25px;
    position: relative;
    background-color: white;
  }

  .nav-menu-mobile::before {
    content: "";
    position: absolute;
    margin-bottom: 5px;
    height: 100%;
    width: 50%;
    bottom: 100%;
    background: inherit;
    transition: transform 150ms ease-out;
    transform-origin: center;
  }

  .nav-menu-mobile::after {
    content: "";
    position: absolute;
    margin-top: 5px;
    height: 100%;
    width: 100%;
    top: 100%;
    background: inherit;
    transition: transform 150ms ease-out;
    transform-origin: center;
  }

  .menu-close::before {
    transform: translate3d(0, calc(100% + 5px), 0);
  }

  .menu-close::after {
    transform: translate3d(0, calc(-100% - 5px), 0);
  }

  .nav_links {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0;
    height: auto;
    width: auto;
    background: none;
  }

  .nav_item {
    padding: 5px;
    margin: 0;
    font-size: 22px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    letter-spacing: 2px;
  }
  .nav_item:nth-child(1)::before {
    content: "~";
  }
  .nav_item:nth-child(3)::before {
    content: "./";
  }

  .item_hov {
    display: none;
  }
}
