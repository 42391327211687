.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 350ms ease-out;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 350ms ease-out;
}

@media only screen and (max-width: 868px) {
  .fade-enter-active,
  .fade-exit-active {
    transition-duration: 400ms;
  }
}
