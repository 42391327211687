* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root {
  height: 100vh;
  padding: 50px;
  background: #e9e9e9;
}

.app {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  --theme: rgb(0, 120, 255);
  border-radius: 5px;
  overflow: hidden;
}

.app > .page_container > .app_page::-webkit-scrollbar {
  width: 4px;
  width: 0px;
  background-color: transparent;
}

.app > .page_container > .app_page::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
}

.page_container {
  height: 100%;
  position: relative;
  overflow: hidden;
  background: var(--theme);
}

.page_container::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 35px;
  height: 0;
  bottom: 0;
  left: 0;
  opacity: 0.75;
  box-shadow: 0 20px 60px 35px var(--theme);
}

/* MEDIA */

@media only screen and (max-width: 868px) {
  #root {
    padding: 0;
  }

  .app {
    width: 100%;
    z-index: 1000;
    overflow: hidden;
    border-radius: 0;
  }
  .app > .app_page::-webkit-scrollbar {
    display: none;
  }

  .app > .app_page::-webkit-scrollbar-thumb {
    display: none;
  }

  .page_container {
    border-radius: 0;
  }
}
